<template>
    <div>
        <b-modal ref="popup-form-usuario"
                 hide-footer centered fixed
                 title="Manutenção de Usuários"
                 size="lg">

            <b-container fluid>
                <b-row cols="2" class="form-usuario">
                    <b-col>
                        <input-padrao classes="m-0" label="Login *" v-model.trim="$v.username.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.username.$invalid">Campo login é obrigátório</p>
                    </b-col>
                    <b-col>
                        <input-padrao classes="m-0" label="Nome" v-model.trim="$v.nome.$model" />
                        <p class="txt-erro-validacao"></p>
                    </b-col>
                    <b-col>
                        <input-padrao classes="m-0" label="E-mail" v-model.trim="$v.email.$model" />
                        <p class="txt-erro-validacao"></p>
                    </b-col>
                    <b-col>
                        <input-padrao classes="m-0" tipo="number" label="Nº Atendimentos" v-model="atendimentoSimultaneo" />
                    </b-col>
                    <b-col>
                        <div class="w-100">
                            <label class="w-100 m-0 h6 font-size-label-input-select">Supervisor</label>
                            <select required class="w-100 input-select" v-model="supervisor">
                                <option selected value="">Selecione...</option>
                                <option v-for="(opc, i) in listaSupervisores" :key="i" :value="opc.Id">
                                    {{ opc.Nome }}
                                </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="w-100">
                            <label class="w-100 m-0 h6 font-size-label-input-select">Perfil</label>
                            <select class="w-100 input-select" v-model="perfil">
                                <option selected value="">Selecione...</option>
                                <option v-for="(opc, i) in listaPerfis" :key="i" :value="opc.Value">
                                    {{ opc.Text }}
                                </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col>
                        <input-select label="Habilitar Transferência Nominal de Atendimento?" :opcoes="opcoesBooleano" v-model="atendimentoTransferenciaNominal" />
                    </b-col>
                    <b-col>
                        <input-select label="Habilitar Chamada Ativa?" :placeholder="null" :opcoes="opcoesBooleano" v-model="telefoniaChamadaAtiva" />
                    </b-col>
                    <b-col>
                        <input-select label="Habilitar Escolha de Canais?" :placeholder="null" :opcoes="opcoesBooleano" v-model="habilitarEscolhaDeCanal" />
                    </b-col>
                    <b-col>
                        <label class="custom-field">
                            Intervalo Entre Chamadas (segundos)
                            <input type="number" v-model="intervaloEntreChamadas" class="w-100" />
                        </label>
                    </b-col>
                    <b-col v-if="listaGrupos != null && usuarioAutenticado.ModulosEmpresa.split(',').some(value => value == '8')">
                        <label>Grupos habilitados (WhatsApp)</label>
                        <div :style="listaGrupos.length <= 6 ? '' : 'overflow-y: scroll; height: 140px;'" class="fundo-formulario-checar-grupos">
                            <div class="form-check d-flex align-items-center justify-content-between"
                                 v-for="grupo in listaGrupos"
                                 :key="grupo.CodigoProtocolo">
                                <input class="form-check-input"
                                       type="checkbox"
                                       :id="'grupo-' + grupo.CodigoProtocolo"
                                       :value="grupo.CodigoProtocolo"
                                       @input="editarGruposHabilitado">
                                <label class="form-check-label" :for="'grupo-' + grupo.CodigoProtocolo">
                                    {{grupo.NomeGrupo}}
                                </label>
                                <botao-tab-remove @click="removerGrupo(grupo.Id)" />
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <div class="w-100 text-right mt-2">
                <botao-modal-cancel class="d-inline mr-2" @click="fecharModal">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvarUsuario">Salvar</botao-modal-ok>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters } from 'vuex'
    import { required, email, requiredIf } from "vuelidate/lib/validators";
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'

    export default {
        name: 'UsuarioFormPopup',
        components: {
            InputPadrao,
            InputSelect,
            BotaoModalOk,
            BotaoModalCancel,
            BotaoTabRemove
        },
        props: {
            listaPerfis: {
                type: Array,
                default: () => []
            },
            listaGrupos: {
                type: Array,
                default: () => []
            },
            listaSupervisores: []

        },
        data() {
            return {
                id: 0,
                empresaId: '',
                username: '',
                nome: '',
                fechar: false,
                sobrenome: '',
                nomeReduzido: '',
                email: '',
                supervisor: '',
                perfil: '',
                integracoesHabilitadas: '',
                telefoniaAtivo: false,
                telefoniaServidor: "",
                telefoniaUsuario: "",
                telefoniaSenha: "",
                telefoniaChamadaAtiva: false,
                habilitarEscolhaDeCanal: false,
                intervaloEntreChamadas: 0,
                atendimentoSimultaneo: 0,
				atendimentoTransferenciaNominal: false,
                clickSalvar: false,

				opcoes: [{ text: 'Sim', value: 'Sim' }, { text: 'Não', value: 'Não' }],
				opcoesBooleano: [{ text: "Sim", value: true }, { text: "Não", value: false }],
            }
        },

        validations: {
            username: { required },
            nome: { required },
            email: { required, email },
            supervisor: { required },
            perfil: { required },
        },
        computed: {
           ...mapGetters({
               listaUsuarios: 'usuarios/listaUsuarios',
               listaUsuariosPerfis: 'usuarios/listaUsuariosPerfis',
               usuarioAutenticado: 'authentication/authDadosUsuario',
           }),
           supervisores: function () {
               //inserir axios
               
               if (this.listaUsuarios) {
                   return this.listaUsuarios.filter(f => [2,3].includes(f.Perfil)).map(prop => ({
                       value: prop.Id,
                       text: prop.UserName
                   }))
               }
               return [];
           },
           perfis: function () {
               if (this.listaUsuariosPerfis) {
                   return this.listaUsuariosPerfis.map(prop => ({
                       value: prop.Id,
                       text: prop.Nome
                   }));
               }
               return [];
           }
        },
        created() {
           // console.log("oaas", this.listaDeUsuarios)
        },
        methods: {
            showModal() {
                this.$refs['popup-form-usuario'].show()

                return new Promise(resolve => this.fechar = resolve);
            },
            editarUsuario: function (usuario) {
                this.id = usuario.Id;
                this.empresaId = usuario.EmpresaId;
                this.username = usuario.UserName;
                this.nome = usuario.Nome;
                this.sobrenome = usuario.Sobrenome;
                this.nomeReduzido = usuario.NomeReduzido;
                this.email = usuario.Email;
                this.atendimentoSimultaneo = usuario.AtendimentoSimultaneo;
                this.supervisor = usuario.SupervisorId;
                this.perfil = usuario.PerfilNumero,
                this.atendimentoTransferenciaNominal = usuario.AtendimentoTransferenciaNominal;
                this.telefoniaChamadaAtiva = usuario.TelefoniaChamadaAtiva;
                this.habilitarEscolhaDeCanal = usuario.HabilitarEscolhaDeCanal;
                this.intervaloEntreChamadas = usuario.IntervaloEntreChamadas;
                this.integracoesHabilitadas = usuario.GruposHabilitados;
                this.setarGruposHabilitados(this.integracoesHabilitadas);
            },
            fecharModal: function () {
                this.$refs['popup-form-usuario'].hide();
                this.limparFormulario();
            },
            limparFormulario: function () {
                this.clickSalvar = false;

                this.id = '';
                this.empresaId = '';
                this.username = '';
                this.nome = '';
                this.sobrenome = '';
                this.nomeReduzido = '';
                this.email = '';
                this.supervisor = '';
                this.perfil = 0;
                this.telefoniaAtivo = false;
				this.telefoniaServidor = "";
				this.telefoniaUsuario = "";
				this.telefoniaSenha = "";
				this.telefoniaChamadaAtiva = false;
                this.habilitarEscolhaDeCanal = false;
                this.intervaloEntreChamadas = 0;
                this.integracoesHabilitadas = '';
            },
            salvarUsuario: function () {
                this.clickSalvar = true;

                // this.$v.$touch();
                // if (this.$v.$invalid) {
                //     return
                // }

                const usuario = JSON.parse(localStorage.getItem('login'));
                let dados = {
                    "Id": this.id,
                    "EmpresaId": this.empresaId ? this.empresaId : usuario.empresaId,
                    "UserName": this.username,
                    "Nome": this.nome,
                    "Sobrenome": this.sobrenome,
                    "NomeReduzido": this.nomeReduzido,
                    "Email": this.email,
                    "SupervisorId": this.supervisor,
                    "Perfil": this.perfil,
                    "AtendimentoSimultaneo": this.atendimentoSimultaneo,
                    "AtendimentoTransferenciaNominal": this.atendimentoTransferenciaNominal,
                    "TelefoniaChamadaAtiva": this.telefoniaChamadaAtiva,
                    "HabilitarEscolhaDeCanal": this.habilitarEscolhaDeCanal,
                    "IntervaloEntreChamadas": this.intervaloEntreChamadas,
                    "GruposHabilitados": this.integracoesHabilitadas
                }
                if(this.id){
                    axios.put(`api/usuario`,dados).then(() => {
                        this.fechar(true);
                    })
                }
                else{
                    dados.Id = null;
                    axios.post(`api/usuario`,dados).then(() => {
                        this.fechar(true);
                    });
                }
                //this.$store.dispatch(`usuarios/${this.id ? 'editar' : 'adicionar'}`, dados);
                this.fecharModal();
            },
            editarGruposHabilitado($event) {
                let arrayIdGrupos = this.integracoesHabilitadas ? this.integracoesHabilitadas?.split(',').filter(Boolean) : [];

                if ($event.target.checked) {
                    arrayIdGrupos.push($event.target.value)
                }

                if (!$event.target.checked) {
                    arrayIdGrupos = arrayIdGrupos.filter(value => value !== $event.target.value)
                }

                this.integracoesHabilitadas = arrayIdGrupos.sort((a, b) => a - b).toString();
            },
            setarGruposHabilitados(grupos) {
                let gruposHabilitados = grupos?.split(',');
                if(grupos){
                    setTimeout(() => {
                        gruposHabilitados.forEach(value => {
                            let elementoGrupoHabilitado = document.getElementById('grupo-' + value);
                            if (elementoGrupoHabilitado) elementoGrupoHabilitado.checked = true;
                        });
                    }, 300);
                }
            },
            removerGrupo(id) {
                this.$emit('removerGrupo', id);
            }
        },
    }
</script>

<style scoped>
	label {
		font-size: 14px;
		margin: 0;
	}

	.fundo-formulario-checar-grupos {
		background-color: var(--cinza-2);
		border: solid 1px var(--cinza-4);
	}

	.txt-erro-validacao {
		color: red;
		font-size: 12px;
		margin: 0;
	}

	.form-usuario > .col {
		margin-bottom: 15px;
	}

	.imap-forms-config {
		cursor: pointer;
		opacity: .5;
		transition: all ease-in-out .3s;
	}

		.imap-forms-config:hover {
			opacity: 1;
		}

	label.custom-field {
		font-size: 12px;
		font-weight: bold;
		line-height: 24px;
	}

		label.custom-field > input {
			background-color: var(--cinza-2);
			line-height: 18px;
			color: var(--cinza-5);
		}
</style>